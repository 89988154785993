<template>
  <div>
    <Top />
    <v-container style="max-width: 900px; padding-bottom: 69px" class="mt-2">
      <v-row>
        <v-col cols="6" class="text-left">
          <v-sheet class="pa-4">
            <v-avatar class="mb-4" color="grey darken-1" size="120"
              ><img :src="avatar" alt="Avatar" srcset="" width="120"
            /></v-avatar>
          </v-sheet>
        </v-col>
        <v-col
          cols="6"
          class="d-flex"
          style="justify-content: center; align-items: center"
        >
          <v-row>
            <v-col>
              <h4>Pet dog</h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn block>Segir</v-btn>
        </v-col>
        <v-col>
          <v-btn block>Messagem</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col><b>11</b> publicações</v-col>
            <v-col><b>196</b> seguidores</v-col>
            <v-col><b>222</b> seguindo</v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12">
          <v-item-group v-model="selected" multiple>
            <v-row>
              <v-col
                v-for="(item, i) in list"
                :key="i"
                cols="12"
                sm="4"
                style="padding: 2px !important"
              >
                <v-item>
                  <v-img :src="post" class="text-right pa-2" @click="goTo()">
                    <v-btn icon dark>
                      <v-icon> mdi-content-copy </v-icon>
                    </v-btn>
                  </v-img>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-col>
      </v-row>
    </v-container>
    <bottom-navigation></bottom-navigation>
  </div>
</template>
<script>
export default {
  data() {
    return {
      avatar: require("@/assets/avatar.jpg"),
      post: require("@/assets/post.jpg"),
      list: [6, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
    };
  },
  methods: {
    goTo() {
      return this.$router.replace("/time_line");
    },
  },
};
</script>